<template >
  <dialog-component
    :title="getTitle"
    :width="getIsMobile ? '100%' : '650px'"
    :visible="dialogFormVisible"
    :containerWidth="'50%'"
    @before-close="showAddEditEntityGroup"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <el-form
      :model="newGroupForm"
      v-loading="loading"
      label-position="top"
      class="addEditEntityGroups"
    >
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item >
            <!-- label="Group Title" -->
            Group Title
            <el-input
              v-model="newGroupForm.name"
              autocomplete="off"
              placeholder="Enter Title"
            ></el-input>
            <p
              class="error"
              v-if="
                !newGroupForm.name ||
                !newGroupForm.name.length ||
                newGroupForm.name === ' '
              "
            >
              * Please add group title *
            </p>
            <!-- <p
              class="err"
              v-if="getEntityGroupErrors && getEntityGroupErrors.name"
            >
              {{ getEntityGroupErrors.name }}
            </p> -->
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item >
            <!-- label="Select An Entity" -->
            Select An Entity <br>
            <el-select
              v-model="newGroupForm.entities"
              placeholder="Select Entity"
              multiple
              collapse-tags
              filterable
            >
              <el-option
                v-for="(entity, index) of allEntities"
                :key="index"
                :label="entity.name"
                :value="entity._id"
              ></el-option>
            </el-select>
            <p
              class="err"
              v-if="getEntityGroupErrors && getEntityGroupErrors.entities"
            >
              {{ getEntityGroupErrors.entities }}
            </p>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item >
            <!-- label="Select Form Templates" -->
            Select Form Templates
            <el-select
              v-model="newGroupForm.formTemplates"
              placeholder="Select Template"
              multiple
              collapse-tags
              filterable
            >
              <el-option
                v-for="(formTemplate, index) of allFormTemplates"
                :key="index"
                :label="formTemplate.name"
                :value="formTemplate._id"
              ></el-option>
            </el-select>
            <p
              class="err"
              v-if="getEntityGroupErrors && getEntityGroupErrors.formTemplates"
            >
              {{ getEntityGroupErrors.formTemplates }}
            </p>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item >
            <!-- label="Select Global Variables" -->
            Select Global Variables
            <el-select
              v-model="newGroupForm.globalVariables"
              placeholder="Select Global Variable"
              multiple
              collapse-tags
              filterable
            >
              <el-option
                v-for="(variable, index) of allGlobalVariables"
                :key="index"
                :label="variable.label"
                :value="variable._id"
              ></el-option>
            </el-select>
            <p
              class="err"
              v-if="
                getEntityGroupErrors && getEntityGroupErrors.globalVariables
              "
            >
              {{ getEntityGroupErrors.globalVariables }}
            </p>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item>
            <!-- label="Select Workflows" -->
            Select Workflows <br>
            <el-select
              v-model="newGroupForm.workflows"
              placeholder="Select Workflows"
              multiple
              collapse-tags
              filterable
            >
              <el-option
                v-for="(workflow, index) of allWorkflows"
                :key="index"
                :label="workflow.name"
                :value="workflow._id"
              ></el-option>
            </el-select>
            <p
              class="err"
              v-if="getEntityGroupErrors && getEntityGroupErrors.workflows"
            >
              {{ getEntityGroupErrors.workflows }}
            </p>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item >
            <!-- label="Select Document Templates" -->
            Select Document Templates
            <el-select
              v-model="newGroupForm.docTemplates"
              placeholder="Select Document Template"
              multiple
              collapse-tags
              filterable
            >
              <el-option
                v-for="(docTemplate, index) of allDocTemplates"
                :key="index"
                :label="docTemplate.title"
                :value="docTemplate._id"
              ></el-option>
            </el-select>
            <p
              class="err"
              v-if="getEntityGroupErrors && getEntityGroupErrors.docTemplates"
            >
              {{ getEntityGroupErrors.docTemplates }}
            </p>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item >
            <!-- label="Select Document Templates" -->
            Select Form Builder
            <el-select
              v-model="newGroupForm.formbuilders"
              placeholder="Select Form Builder"
              multiple
              collapse-tags
              filterable
            >
              <el-option
                v-for="(formbuilders, index) of allFormBuilders"
                :key="index"
                :label="formbuilders.name"
                :value="formbuilders._id"
              ></el-option>
            </el-select>
            <p
              class="err"
              v-if="getEntityGroupErrors && getEntityGroupErrors.formbuilders"
            >
              {{ getEntityGroupErrors.formbuilders }}
            </p>
          </el-form-item>
        </el-col>
      </el-row>

      <!-- <p
        class="err"
        v-if="getEntityGroupErrors && getEntityGroupErrors.critical_error "
      >
        {{ getEntityGroupErrors.critical_error }}
      </p> -->
    </el-form>
    <span
      slot="footer"
      class="dialog-footer"
    >
      <el-row :gutter="5">
        <el-col
          :span="1"
          :offset="14"
        >
          <el-button @click="showAddEditEntityGroup()">Cancel</el-button>
        </el-col>
        <el-col
          :span="1"
          :offset="3"
        >
          <el-button
            style="background-color: var(--primary-color); border-color: var(--primary-color)"
            type="success"
            icon="el-icon-finished"
            :disabled="
              !newGroupForm.name ||
              !newGroupForm.name.length ||
              newGroupForm.name === ' ' ||
              !newGroupForm.name.trim()
            "
            @click="onSubmit"
          >Save Group</el-button>
        </el-col>
      </el-row>
    </span>
    <span
      class="error"
      v-if="getError && !getError.success"
    >
      {{ getError.message }}</span>
  </dialog-component>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: ["existedEntityGroup"],
  data() {
    return {
      dialogFormVisible: false,
      newGroupForm: {
        name: "",
        entities: [],
        formTemplates: [],
        globalVariables: [],
        workflows: [],
        documents: [],
        docTemplates: [],
        formbuilders: [],
      },
      allEntities: [],
      allFormTemplates: [],
      allGlobalVariables: [],
      loading: false,
      entityGroupId: null,
      allWorkflows: [],
      allDocuments: [],
      allDocTemplates: [],
      allFormBuilders: [],
    };
  },
  computed: {
    ...mapGetters("entities", ["getAllEntities"]),
    ...mapGetters("navigationOpen", ["getIsMobile", "getIsRSOpen"]),
    ...mapGetters("entityGroups", [
      "getEntityGroupErrors",
      "getEntityGroupDataAddStatus",
      "getEntityGroupDataUpdateStatus",
      "getError"
    ]),

    ...mapGetters("companyTemplates", ["getAllCompanyTemplatesData"]),
    ...mapGetters("globalVariables", ["getAllGlobalVariables"]),
    ...mapGetters("templateWorkflow", ["getAllWorkflows"]),
    ...mapGetters("documents", ["getAllDocuments", "getAllTemplates"]),
    ...mapGetters("formBuilders", ["getAllFormBuildersData"]),
    getTitle() {
      return this.entityGroupId ? "Update Group" : "Add New Group";
    }
  },
 mounted() {
    this.clearErrors();
    this.dialogFormVisible = true;
    if (this.existedEntityGroup) {
      this.newGroupForm = { ...this.existedEntityGroup };
      this.entityGroupId = this.existedEntityGroup._id;
    }
    this.fetchAllActiveEntities();
    this.fetchTemplates();
    this.fetchAllGlobalVariables();
    this.fetchWorkflows();
    this.fetchDocuments();
    this.fetchDocTemplates();
    this.fetchFormbuilders();
  },
  methods: {
    showAddEditEntityGroup(){
      this.dialogFormVisible=false;
      this.$emit("closeDialog")
    },
    clearErrors() {
      this.$store.dispatch("entityGroups/clearErrors");
    },
    getEntityName(entityId) {
      let entity = this.allEntities.find(x => x._id == entityId);
      if (entity) {
        return entity.name;
      } else {
        return "-";
      }
    },
    getFormTemplateName(formTemplateId) {
      let selectedFormTemplate = this.allFormTemplates.find(
        x => x._id == formTemplateId
      );
      if (selectedFormTemplate) {
        return selectedFormTemplate.name;
      } else {
        return "-";
      }
    },

    getGlobalVariableName(variableId) {
      let selectedVariable = this.allGlobalVariables.find(
        x => x._id == variableId
      );
      if (selectedVariable) {
        return selectedVariable.label;
      } else {
        return "-";
      }
    },
    getFormBuilderName(formbuilderid) {
      let formbuilders = this.allFormBuilders.find((x) => x._id == formbuilderid);
      if (formbuilders) {
        return formbuilders.name;
      } else {
        return "-";
      }
    },
    async fetchAllActiveEntities() {
      this.loading = true;
      let params = {
        get_all: true,
        order_by: "name",
        order_type: "asc"
      };
      // await this.$store.dispatch(
      //   "companyEntities/getAllCompanyEntities",
      //   params
      // );
      await this.$store.dispatch("entities/fetchEntities", params);
      this.allEntities = this.getAllEntities ? this.getAllEntities.data : [];

      this.loading = false;
    },
    async fetchTemplates() {
      this.loading = true;
      let params = {
        get_all: true
      };
      await this.$store.dispatch(
        "companyTemplates/getAllCompanyTemplates",
        params
      );
      this.allFormTemplates =
        this.getAllCompanyTemplatesData && this.getAllCompanyTemplatesData.data
          ? this.getAllCompanyTemplatesData.data
          : [];
      this.loading = false;
    },
    
    async fetchAllGlobalVariables() {
      this.loading = true;
      this.allGlobalVariables = [];
      let params = {
        get_all: true
      };
      await this.$store.dispatch(
        "globalVariables/fetchGlobalVariables",
        params
      );
      this.loading = false;
      this.allGlobalVariables = this.getAllGlobalVariables.data || [];
    },
    async onSubmit() {
      this.loading = true;
      if (
        this.newGroupForm.entities.length ||
        this.newGroupForm.formTemplates.length ||
        this.newGroupForm.workflows.length ||
        this.newGroupForm.docTemplates.length ||
        this.newGroupForm.globalVariables.length||this.newGroupForm.formbuilders.length
      ) {
        // this.$alert.warn("Please Select Entities");
        if (this.entityGroupId) {
          await this.updateEntityGroup();
        } else {
          await this.addEntityGroup();
        }
         this.loading = false;
      } else {
        this.loading = false;
        this.$message.warning(
          "Please at least Select one option from listed categories "
        );

        return;
      }
       this.dialogFormVisible = false;
    },
 async updateEntityGroup() {
      try {
        this.newGroupForm.name = this.newGroupForm.name.trim();
        let params = {
          id: this.entityGroupId,
          ...this.newGroupForm
        };
        await this.$store.dispatch("entityGroups/updateEntityGroup", params);
        if (this.getEntityGroupDataUpdateStatus) {
          this.$notify.success({
            title: "Success",
            message: "Entity Group Updated Successfuly"
          });
         
          await this.$store.commit(
            "entityGroups/setError",
            {},
            {
              root: true
            }
          );
          this.closeEntityGroup(true);
        } else {
          throw "Error";
        }
      } catch (err) {
        if (!this.getEntityGroupErrors) {
          this.$notify.error();
          ({
            title: "Error",
            message: "Error at Updating Entity"
          });
        }
      }
    },
    async addEntityGroup() {
      try {
        this.newGroupForm.name = this.newGroupForm.name.trim();
        let params = {
          ...this.newGroupForm
        };
        await this.$store.dispatch("entityGroups/addEntityGroup", params);
        if (this.getEntityGroupDataAddStatus) {
          this.$notify.success({
            title: "Success",
            message: "Entity Group Added Successfuly"
          });
          this.closeEntityGroup(true);
          await this.$store.commit(
            "entityGroups/setError",
            {},
            {
              root: true
            }
          );
        } else {
          throw "Error";
        }
      } catch (err) {
        if (!this.getEntityGroupErrors) {
          this.$notify.error();
          ({
            title: "Error",
            message: "Error at Adding Group"
          });
        }
      }
    },
 
    removeEntity(index) {
      this.newGroupForm.entities.splice(index, 1);
    },
    closeEntityGroup(status) {
      this.$emit("groupCreated",status)
      this.$store.commit(
        "entityGroups/setError",
        {},
        {
          root: true
        }
      );
    },
    removeFormTemplate(index) {
      this.newGroupForm.formTemplates.splice(index, 1);
    },
    removeGlobalVariable(index) {
      this.newGroupForm.globalVariables.splice(index, 1);
    },
    async fetchWorkflows() {
      try {
        this.loading = true;
        let params = {
          get_all: true,
          status: "ACTIVE"
        };
        await this.$store.dispatch("templateWorkflow/fetchWorkflows", params);
        if (this.getAllWorkflows) {
          this.allWorkflows =
            this.getAllWorkflows && this.getAllWorkflows.data
              ? this.getAllWorkflows.data
              : [];

          this.loading = false;
        } else {
          this.loading = false;
        }
      } catch (err) {
        console.log("fetchWorkflows",err);
      }
    },
    async fetchDocuments() {
      try {
        let params = {
          get_all: true
        };
        await this.$store.dispatch("documents/fetchAllDocuments", params);
        if (this.getAllDocuments && this.getAllDocuments.data) {
          this.allDocuments = this.getAllDocuments.data;
        } else {
          this.allDocuments = [];
        }
      } catch (err) {
        console.log("fetchDocuments",err);
      }
    },

    async fetchDocTemplates() {
      try {
        let params = {
          limit: 100,
          page: 1,
          status: "ACTIVE"
        };
        await this.$store.dispatch("documents/fetchAllTemplates", params);
        this.allDocTemplates =
          this.getAllTemplates && this.getAllTemplates.data
            ? this.getAllTemplates.data
            : [];
      } catch (err) {
        console.log("fetchDocTemplates",err);
      }
    },
    async fetchFormbuilders() {
      try {
        let params = {
          get_all: true,
        };
        await this.$store.dispatch("formBuilders/fetchAllFormBuilders", params);
        if (this.getAllFormBuildersData) {
          this.allFormBuilders =
            this.getAllFormBuildersData && this.getAllFormBuildersData.data
              ? this.getAllFormBuildersData.data
              : [];
        }
      } catch (err) {
        console.log("fetchFormbuilders",err);
      }
    },
  }
};
</script>
<style lang="scss" scoped>
.el-col-offset-3 {
    margin-left: -68.5%;
}
.addEditEntityGroups {
  .el-form-item__label {
    line-height: 0px !important;
  }
}
.el-form-item__label {
  line-height: 0px !important;
}
</style>
<style>
.err {
  color: #c14e4e;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both;
}
</style>